import { useState } from "react";

const PopUpBeta = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleClick = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  return (
    <div>
      <div className="popup">
        <h1>Coming Soon!</h1>
        <p>Please Check Back Soon!</p>
        <button onClick={handleClick}>Close</button>
      </div>
    </div>
  );
};

export default PopUpBeta;
