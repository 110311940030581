// import logo from "../images/mlogo.png";
// import colorlogo from "../images/mlogo.png";
// import PopUpBeta from "./ComingSoon";
// import { useState } from "react";
// import { Authenticator } from "@aws-amplify/ui-react";
// import "@aws-amplify/ui-react/styles.css";
// import { Auth } from "aws-amplify";

// const Nav = ({ minimal, setShowModal, showModal, setIsSignUp }) => {
//   const [isPopupOpen, setIsPopupOpen] = useState(false);

//   {
//     /* THIS IS WHAT I NEED */
//   }
//   // const handleClick = () => {
//   //   setShowModal(true);
//   //   setIsSignUp(false);
//   // };

//   const handleClick = () => {
//     setIsPopupOpen(!isPopupOpen);
//   };

//   const [isSignedIn, setIsSignedIn] = useState(false);

//   const signOutButton = () => {
//     setIsSignedIn(!isSignedIn);
//   };

//   const authToken = false;

//   return (
//     <nav>
//       <div className="logo-container">
//         <img className="logo" src={minimal ? colorlogo : logo} />
//       </div>

//       {/* THIS IS WHAT I NEED */}
//       {/* {!authToken && !minimal && (
//         <button
//           className="nav-button"
//           onClick={handleClick}
//           disabled={setShowModal}
//         >
//           Log In
//         </button>
//       )} */}
//       {!isSignedIn ? (
//         <button className="nav-button" onClick={handleClick}>
//           Log In | Sign Up
//         </button>
//       ) : (
//         <button className="nav-button" onClick={signOutButton}>
//           Sign Out
//         </button>
//       )}
//       {!isPopupOpen && (
//         <div
//           className="awsAmpAuth"
//           style={{
//             position: "absolute",
//             right: "50%",
//             transform: "translate(50%,+70%)",
//           }}
//         >
//           <Authenticator></Authenticator>
//         </div>
//       )}

//       {/*
//       {isPopupOpen && (
//         <div
//           style={{
//             backgroundColor: "#EB6E68",
//             margin: "10px",
//             padding: "40px",
//             position: "absolute",
//             right: "50%",
//             transform: "translate(50%,+100%)",
//             width: "50%",
//           }}
//         >
//           <h1>Coming soon! Check back later!</h1>
//           {/* <p>You can put anything you want in here</p> */}
//       {/* <button className="primary-button" onClick={handleClick}>
//             Close
//           </button>
//         </div> */}
//       {/* // )} */}
//     </nav>
//   );
// };

// export default Nav;

// import logo from "../images/mlogo.png";
// import colorlogo from "../images/mlogo.png";
// import React, { useState, useEffect } from "react";
// import { Authenticator } from "@aws-amplify/ui-react";
// import { Auth, Hub } from "aws-amplify";

// const Nav = ({ minimal }) => {
//   const [user, setUser] = useState(null);

//   useEffect(() => {
//     const checkUser = async () => {
//       try {
//         const userData = await Auth.currentAuthenticatedUser();
//         setUser(userData);
//       } catch (error) {
//         console.error("Error checking authenticated user", error);
//         setUser(null);
//       }
//     };

//     checkUser();

//     const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
//       if (event === "signIn" || event === "signUp") {
//         setUser(data);
//       } else if (event === "signOut") {
//         setUser(null);
//       }
//     });

//     return () => unsubscribe();
//   }, []);

//   const signOut = async () => {
//     try {
//       await Auth.signOut();
//     } catch (error) {
//       console.log("error signing out:", error);
//     }
//   };

//   if (!user) {
//     return (
//       <div
//         className="awsAmpAuth"
//         style={{
//           position: "absolute",
//           right: "50%",
//           transform: "translate(50%,+70%)",
//         }}
//       >
//         <Authenticator />
//       </div>
//     );
//   }

//   return (
//     <nav>
//       <div className="logo-container">
//         <img className="logo" src={minimal ? colorlogo : logo} alt="logo" />
//       </div>

//       <button className="nav-button" onClick={signOut}>
//         Sign Out
//       </button>
//     </nav>
//   );
// };

// export default Nav;

import React, { useState, useEffect } from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import { Auth, Hub } from "aws-amplify";
import logo from "../images/mlogo.png";
import colorlogo from "../images/mlogo.png";
import "@aws-amplify/ui-react/styles.css";

const Nav = ({ minimal }) => {
  const [user, setUser] = useState(null);
  const [showAuth, setShowAuth] = useState(false);

  useEffect(() => {
    const checkUser = async () => {
      try {
        const userData = await Auth.currentAuthenticatedUser();
        setUser(userData);
      } catch (error) {
        console.error("Error checking authenticated user", error);
        setUser(null);
      }
    };

    checkUser();

    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      if (event === "signIn" || event === "signUp") {
        setUser(data);
        setShowAuth(false);
      } else if (event === "signOut") {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const signOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out:", error);
    }
  };

  return (
    <nav>
      <div className="logo-container">
        <img className="logo" src={minimal ? colorlogo : logo} alt="logo" />
      </div>
      {!user ? (
        <button className="nav-button" onClick={() => setShowAuth(true)}>
          Log In | Sign Up
        </button>
      ) : (
        <button className="nav-button" onClick={signOut}>
          Sign Out
        </button>
      )}
      {showAuth && !user && (
        <div
          className="awsAmpAuth"
          style={{
            position: "absolute",
            right: "50%",
            transform: "translate(50%,+70%)",
          }}
        >
          <Authenticator></Authenticator>
          <button
            style={{ padding: 10, margin: 10 }}
            onClick={() => setShowAuth(false)}
          >
            Close
          </button>
        </div>
      )}
    </nav>
  );
};

export default Nav;
