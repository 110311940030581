import { Button } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import ErrorMessage from "./ErrorMessage";
import {
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Grid,
  Container,
  Box,
  Paper,
  TextField,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { css } from "@emotion/react";
import { useTheme } from "@mui/material";

const Question = ({
  currQues,
  setCurrQues,
  questions,
  options,
  handleQuizData,
  quizData,
  // setQuestions,
}) => {
  const theme = useTheme();

  const styles = {
    custom_1: css`
      & .MuiRadio-root.Mui-checked {
        color: #7baabe;
      }
      ,
      &
        .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
        .MuiOutlinedInput-notchedOutline {
        border-color: #7baabe !important;
      }
    `,
  };

  const [selected, setSelected] = useState();
  const [error, setError] = useState(false);

  const [textData, setTextData] = useState(null);

  const navigate = useNavigate();

  let userAnswers = [];

  const handleSelect = (i) => {
    if (selected === i) return "select";
  };

  const handleCheck = (i) => {
    setSelected(i);
    setError(false);
  };

  const handleNext = () => {
    if (currQues === Object.keys(questions).length - 1 && !textData) {
      // userAnswers.push({
      //   key: questions[currQues].question,
      //   val: selected.target.value,
      // });
      // handleQuizData(userAnswers);

      setSelected();
      setTextData();
      let finalState = {
        ...quizData,
        [questions[currQues].question]: selected.target.value,
      };
      navigate("/signup", { state: finalState });
    } else if (currQues === Object.keys(questions).length - 1 && textData) {
      // userAnswers.push({
      //   key: questions[currQues].question,
      //   val: textData,
      // });
      // handleQuizData(userAnswers);

      setSelected();
      setTextData();
      let finalState = {
        ...quizData,
        [questions[currQues].question]: textData,
      };
      navigate("/signup", { state: finalState });
    } else if (selected && !textData) {
      userAnswers.push({
        key: questions[currQues].question,
        val: selected.target.value,
      });
      handleQuizData(userAnswers);

      setCurrQues(currQues + 1);
      setSelected();
    } else if (selected && textData) {
      userAnswers.push({
        key: questions[currQues].question,
        val: textData,
      });
      handleQuizData(userAnswers);

      setCurrQues(currQues + 1);
      setSelected();
      setTextData();
    } else setError("Please select an option first");
  };

  const handleQuit = () => {
    setCurrQues(0);
  };

  const handlePrev = () => {
    if (currQues === 0) {
      setCurrQues(0);
      navigate("/discover");
    } else {
      setError(false);
      setCurrQues(currQues - 1);
    }
  };

  const getTextData = (val) => {
    setTextData(val.target.value);
    setSelected(val.target.value);
    setError(false);
  };

  return (
    <Container
      alignItems="center"
      justifyContent="center"
      display="flex"
      direction="column"
      style={{
        // backgroundColor: "blue",
        padding: 40,
      }}
    >
      <CssBaseline>
        <Paper
          alignItems="center"
          justifyContent="center"
          display="flex"
          direction="column"
          elevation="3"
          style={{
            padding: 40,
            marginTop: 80,
            // height: "80vh",
          }}
        >
          <Box
            sx={{
              // marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="question">
              {/* <h1>Question {currQues + 1} :</h1> */}
              <h1>{questions[currQues].question}</h1>

              <div className="singleQuestion">
                {/* <h2>{questions[currQues].question}</h2> */}
                <div className="options">
                  {error && <ErrorMessage>{error}</ErrorMessage>}

                  <FormControl>
                    {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
                    {questions[currQues].answer.length > 0 ? (
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        // defaultValue="female"
                        name="radio-buttons-group"
                        sx={styles.custom_1}
                      >
                        {questions[currQues].answer &&
                          questions[currQues].answer.map((i) => (
                            <FormControlLabel
                              value={i}
                              control={<Radio />}
                              label={i}
                              onClick={handleCheck}
                              defaultChecked={false}
                              sx={styles.custom_1}
                            />
                          ))}
                      </RadioGroup>
                    ) : (
                      <TextField
                        label=""
                        variant="outlined"
                        onChange={getTextData}
                        sx={styles.custom_1}
                      />
                    )}
                  </FormControl>
                </div>
                <div className="controls">
                  <Grid
                    container
                    item
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      variant="contained"
                      size="large"
                      style={{ width: 185, backgroundColor: "#7baabe" }}
                      onClick={handleNext}
                    >
                      {currQues > 20 ? "Submit" : "Next Question"}
                    </Button>
                  </Grid>

                  <Grid
                    container
                    item
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    {/* <Button
                      variant="contained"
                      size="large"
                      style={{ width: 185, backgroundColor: "#eb6e68" }}
                      href="/"
                      onClick={() => handleQuit()}
                    >
                      Quit
                    </Button> */}
                    <Button
                      variant="contained"
                      size="large"
                      style={{ width: 185, backgroundColor: "#eb6e68" }}
                      onClick={() => handlePrev()}
                    >
                      Prev
                    </Button>
                  </Grid>
                </div>
              </div>
            </div>
          </Box>
        </Paper>
      </CssBaseline>
    </Container>
  );
};

export default Question;
