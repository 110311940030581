import React from "react";
import { useState } from "react";
import AuthModal from "../components/AuthModal";
import {
  Button,
  MenuItem,
  TextField,
  Container,
  Box,
  Grid,
  Paper,
} from "@mui/material";
import { css } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { useTheme } from "@mui/material/styles";

// import { useHistory } from "react-router";
import { useNavigate, useLocation } from "react-router-dom";

import ErrorMessage from "../components/ErrorMessage";
// import StepOne from "../components/StepOne";
// import StepTwoA from "../components/StepTwoA";
// import StepTwoB from "../components/StepTwoB";
// import StepTwoC from "../components/StepTwoC";
// import Final from "../components/Final";

const DiscoverConclude = ({ quizData }) => {
  const theme = useTheme();

  const { state } = useLocation();

  const styles = {
    custom_1: css`
      & label.Mui-focused {
        color: #7baabe;
      }
      ,
      & .MuiOutlinedInput-root {
        &:hover fieldset {
          border-color: #7baabe !important;
        },
        &.Mui-focused fieldset {
          border-color: #7baabe !important;
        },
      }
    `,
  };

  // const [questions, setQuestions] = useState();

  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = () => {};

  console.log(state);

  return (
    <div className="quiz_background">
      <Container
        alignItems="center"
        justifyContent="center"
        display="flex"
        direction="column"
        style={
          {
            // backgroundColor: "blue",
            // height: "100vh",
          }
        }
      >
        <CssBaseline>
          <Paper
            alignItems="center"
            justifyContent="center"
            display="flex"
            direction="column"
            elevation="3"
            style={{
              padding: 20,
              marginTop: 80,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid
                container
                style={{ margin: 40 }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {error && (
                  <ErrorMessage>Please Fill all the feilds</ErrorMessage>
                )}
                <Grid
                  container
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <h1>Sign Up (It's Free!) To See Your Results</h1>
                </Grid>
                <Grid
                  container
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <AuthModal isSignUp={true} xShow={false} />
                </Grid>
                <Grid
                  container
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                ></Grid>
              </Grid>
            </Box>
          </Paper>
        </CssBaseline>
      </Container>
    </div>
  );
};

export default DiscoverConclude;
