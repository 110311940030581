import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import Question from "../components/Question";
import { useLocation } from "react-router-dom";
import { Box, Paper } from "@mui/material";
import { flushSync } from "react-dom";

const Quiz = ({ questions }) => {
  const [options, setOptions] = useState();
  const [currQues, setCurrQues] = useState(0);
  const [quizData, setQuizData] = useState({});
  // const [quizData, setQuizData] = useState([]);

  const { state } = useLocation();

  const handleShuffle = (options) => {
    return options.sort(() => Math.random() - 0.5);
  };

  const handleQuizData = (data) => {
    // var obj = quizData
    // obj.question
    // obj.push([data[0].key]: data[0].val)
    // setQuizData(obj)

    // setQuizData((current) => [...current, ...data]);

    setQuizData({ ...quizData, [data[0].key]: data[0].val });
  };

  console.log(quizData);

  return (
    <div className="quiz_background">
      {questions ? (
        <>
          <Question
            currQues={currQues}
            setCurrQues={setCurrQues}
            questions={questions[state[0]]}
            options={options}
            handleQuizData={handleQuizData}
            quizData={quizData}
            // setQuestions={setQuestions}
          />
        </>
      ) : (
        <CircularProgress
          style={{ margin: 100 }}
          color="inherit"
          size={150}
          thickness={1}
        />
      )}
    </div>
  );
};

export default Quiz;
