import Nav from "../components/Nav";
import { useState } from "react";
import AuthModal from "../components/AuthModal";
import { useNavigate } from "react-router-dom";
import PopUpBeta from "../components/ComingSoon";

const Home = () => {
  const [showModal, setShowModal] = useState(false);

  const [isSignUp, setIsSignUp] = useState(true);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const navigate = useNavigate();

  const authToken = false;

  const handleClick = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  // THIS IS WHAT I NEED
  // const handleClick = () => {
  //   navigate("/discover");
  // };

  return (
    <div className="overlay">
      <Nav
        minimal={false}
        setShowModal={setShowModal}
        showModal={showModal}
        setIsSignUp={setIsSignUp}
      />
      <div className="home">
        <h1 className="primary-title">Love, Friends, & Connection</h1>
        {authToken ? (
          ""
        ) : (
          <h2
            style={{
              fontSize: "30px",
              color: "rgb(36, 36, 36)",
            }}
          >
            Your Next Match or Adventure is Moments Away!
          </h2>
        )}

        {/* THIS IS WHAT I NEED */}
        {/* <button className="primary-button" onClick={handleClick}>
          {authToken ? "Signout" : "Take the Quiz"}
        </button> */}

        <button className="primary-button" onClick={handleClick}>
          Take the Quiz!
        </button>
        {isPopupOpen && (
          <div
            style={{
              backgroundColor: "#EB6E68",
              margin: "10px",
              padding: "10px",
            }}
          >
            <h1>Coming soon! Check back later!</h1>
            {/* <p>You can put anything you want in here</p> */}
            <button className="primary-button" onClick={handleClick}>
              Close
            </button>
          </div>
        )}

        {showModal && (
          <AuthModal setShowModal={setShowModal} isSignUp={isSignUp} />
        )}
      </div>
    </div>
  );
};

export default Home;
