import React from "react";
import { useState } from "react";
import {
  Button,
  MenuItem,
  TextField,
  Container,
  Box,
  Grid,
  Paper,
} from "@mui/material";
import { css } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { useTheme } from "@mui/material/styles";

// import { useHistory } from "react-router";
import { useNavigate } from "react-router-dom";

import ErrorMessage from "../components/ErrorMessage";

const DiscoverForm = () => {
  const theme = useTheme();

  const styles = {
    custom_1: css`
      & label.Mui-focused {
        color: #7baabe;
      }
      ,
      & .MuiOutlinedInput-root {
        &:hover fieldset {
          border-color: #7baabe !important;
        },
        &.Mui-focused fieldset {
          border-color: #7baabe !important;
        },
      }
    `,
  };

  // const [questions, setQuestions] = useState();
  const [gender, setGender] = useState("");
  const [interest, setInterest] = useState("");
  const [datingInterest, setDatingInterest] = useState("");
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const fetchQuestions = (gender, interest, datingInterest) => {
    switch ((gender, interest, datingInterest)) {
      case "man" && "dating" && "woman":
        return ["q1a", "q1b"];
      case "female" && "dating" && "man":
        return "q2";
      default:
        return "q3";
    }
  };

  const handleSubmit = () => {
    if (gender && interest === "friends" && !datingInterest) {
      setError(false);
      const qkey = fetchQuestions(gender, interest, datingInterest);
      // console.log(qkey);
      navigate("/quiz", { state: { name: qkey } });
    } else if (!gender || !interest || !datingInterest) {
      setError(true);
      return;
    } else {
      setError(false);
      const qkey = fetchQuestions(gender, interest, datingInterest);
      // console.log(qkey);
      navigate("/quiz", { state: qkey });
    }
  };

  return (
    <div className="quiz_background">
      <Container
        alignItems="center"
        justifyContent="center"
        display="flex"
        direction="column"
        style={
          {
            // backgroundColor: "blue",
            // height: "100vh",
          }
        }
      >
        <CssBaseline>
          <Paper
            alignItems="center"
            justifyContent="center"
            display="flex"
            direction="column"
            elevation="3"
            style={{
              padding: 20,
              marginTop: 80,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid
                container
                style={{ margin: 40 }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {error && (
                  <ErrorMessage>Please Fill all the feilds</ErrorMessage>
                )}
                <Grid
                  container
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <h1>First, let's cover the basics...</h1>
                </Grid>
                <Grid
                  container
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    select
                    label="Select Gender"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    variant="outlined"
                    style={{
                      marginBottom: 30,
                      minWidth: "50%",
                      textAlign: "left",
                    }}
                    sx={styles.custom_1}
                  >
                    <MenuItem key={"man"} value={"man"}>
                      {"Man"}
                    </MenuItem>
                    <MenuItem key={"woman"} value={"woman"}>
                      {"Woman"}
                    </MenuItem>
                    <MenuItem key={"other"} value={"other"}>
                      {"Other"}
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid
                  container
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    select
                    label="Select Interest"
                    value={interest}
                    onChange={(e) => setInterest(e.target.value)}
                    variant="outlined"
                    style={{
                      marginBottom: 30,
                      minWidth: "50%",
                      textAlign: "left",
                    }}
                    sx={styles.custom_1}
                  >
                    <MenuItem key={"dating"} value={"dating"}>
                      {"Dating"}
                    </MenuItem>
                    <MenuItem key={"friends"} value={"friends"}>
                      {"Friends"}
                    </MenuItem>
                    <MenuItem key={"both"} value={"both"}>
                      {"Everything"}
                    </MenuItem>
                  </TextField>
                </Grid>
                {(gender && interest === "dating") || interest === "both" ? (
                  <Grid
                    container
                    item
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      select
                      label="Select Dating Interest"
                      value={datingInterest}
                      onChange={(e) => setDatingInterest(e.target.value)}
                      variant="outlined"
                      style={{
                        marginBottom: 30,
                        minWidth: "50%",
                        textAlign: "left",
                      }}
                      sx={styles.custom_1}
                    >
                      <MenuItem key="man" value="man">
                        Man
                      </MenuItem>
                      <MenuItem key="woman" value="woman">
                        Woman
                      </MenuItem>
                      <MenuItem key="everything" value="everything">
                        Everything
                      </MenuItem>
                    </TextField>
                  </Grid>
                ) : (
                  ""
                )}
                <Grid
                  container
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    onClick={handleSubmit}
                    style={{
                      backgroundColor: "#7baabe",
                    }}
                  >
                    Start Quiz
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </CssBaseline>
      </Container>
    </div>
  );
};

export default DiscoverForm;
