// import logo from "./logo.svg";
// import './App.css';
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Onboarding from "./pages/Onboarding";
import DiscoverForm from "./pages/DiscoverForm";
import Quiz from "./pages/Quiz";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DiscoverConclude from "./pages/DiscoverConclude";
// const [questions, setQuestions] = useState();

const questions = {
  q1a: {
    0: {
      question: "Are you...",
      answer: [
        "Macho",
        "Kind",
        "Confused",
        "Confident",
        "Innocent",
        "Dangerous",
        "Sexy",
        "Religious",
        "A Mamma's Boy",
      ],
    },
    1: {
      question: "Your lucky number (between 0-10)",
      answer: [],
    },
    2: {
      question: "Your taste in movies. Do you prefer...",
      answer: [
        "Drama",
        "Comedy",
        "Horror",
        "Violence",
        "Porn",
        "Romance",
        "Silent",
      ],
    },
    3: {
      question:
        "Add all the numbers in your date of birth. It must come to one number. And that number is?",
      answer: [],
    },
    4: {
      question: "Your taste in women:",
      answer: [
        "Skinny",
        "Voluptuous",
        "Sweet",
        "Tough",
        "Venomous",
        "Erotic",
        "Self-assured",
        "Myopic",
        "Submissive",
      ],
    },
    5: {
      question: "Favorite sport",
      answer: [
        "Car racing",
        "Parkour",
        "Baseball",
        "Martial arts",
        "Sex",
        "Rock climbing",
        "Quidditch",
        "None of the above",
      ],
    },
    6: {
      question: "Favorite food",
      answer: [
        "Sausages",
        "Chicken liver",
        "Tripe",
        "Frog legs",
        "Sannakji (live Octopus)",
        "Fried ants",
        "Crab cakes",
      ],
    },
    7: {
      question: "For help with endurance/performance",
      answer: [
        "Viagra?",
        "Cialis?",
        "Soft whispers?",
        "Caresses?",
        "Tickling?",
        "Dirty talk?",
        "Vibration?",
      ],
    },
    8: {
      question: "The profession you would have wanted",
      answer: [
        "Gynecologist",
        "Private Investigator",
        "Masseur",
        "Plastic surgeon",
        "Designing lingerie",
        "Spy",
        "Food critic",
        "None of the above",
      ],
    },
    9: {
      question: "Your favorite music",
      answer: [
        "Opera",
        "Soul",
        "R&B",
        "Lullaby",
        "Mozart",
        "Funk",
        "Metal",
        "Oldies",
      ],
    },
    10: {
      question: "Have you ever been to",
      answer: [
        "Pyongyang airport?",
        "Buffalo?",
        "Zanzibar?",
        "Brooklyn?",
        "Cape town?",
        "Chattanooga?",
        "Ittoqqortoormiit, Greenland?",
        "Don't like traveling.",
      ],
    },
    11: {
      question: "A description of yourself",
      answer: [
        "Alpha?",
        "Mesomorph?",
        "Skinny?",
        "Mr. Bully",
        "Smart?",
        "Clueless?",
        "Creative?",
        "Z male?",
      ],
    },
    12: {
      question: "Social relevance",
      answer: [
        "Are you generally social?",
        "Anti-social?",
        "Uncomfortable in a group?",
        "Do you have a sense of humor?",
        "Do you enjoy conversation?",
        "Are you in touch with your intellect?",
        "Do you cry easily?",
        "Are you proud of yourself?",
        "Is something missing?",
      ],
    },
    13: {
      question: "Women enjoy men who are",
      answer: [
        "Honest",
        "Bald",
        "Rich",
        "Courageous",
        "Fearless",
        "Ambitious",
        "Romantic",
        "Kinky",
        "Patient",
        "Good in bed",
      ],
    },
    14: {
      question: "If you see a street fight, you...",
      answer: [
        "Run the other way",
        "Try to help",
        "Get anxious",
        "Don't know what to do",
        "Call your mother",
        "Laugh",
        "Take over the scene",
        "Yell and scream",
      ],
    },
  },
  q1b: `Approach anxiety: Getting close makes you anxious. Nervous? You bite one nail and then the others. You get ready but you don’t dare. You suffer. You want but you can’t. Ask Matilda how to approach a woman, difficult or not. Matilda will amaze you and enable you to approach any woman. You will become the guy you always wanted to be. Your lungs will suddenly fill up with French air; you‘ll be able to breathe words of mass seduction, and they will be your own. If you then have too many, send some to Matilda so she can help the needy.`,
  // q2: {
  //   question_1: "Do you like beards?",
  //   answer_1: ["Yes", "No"],
  // },
  // q3: {
  //   question_1: "Do you like dolphins?",
  //   answer_1: ["Yes", "No"],
  // },
};

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/discover" element={<DiscoverForm />} />
        <Route path="/quiz" element={<Quiz questions={questions} />} />
        <Route path="/signup" element={<DiscoverConclude />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
